<template>
  <v-row class="d-flex justify-center mt-n16">
    <v-col cols="12" md="5">
      <v-form>
        <v-card class="card-shadow bg-secondary" v-if="pageFlag=='login'">
          <!-- <div class="card-header-padding text-center card-border-bottom pb-12">
            <div class="text-muted text-center mt-3 mb-4">
              <small>Sign in with</small>
            </div>
            <div class="btn-wrapper text-center">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-neutral py-3 px-6 rounded-sm me-3"
                color="#fff"
              >
                <v-img
                  max-width="20"
                  max-height="20"
                  src="@/assets/img/common/github.svg"
                  class="me-3"
                ></v-img>
                Github
              </v-btn>

              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-neutral py-3 px-6 rounded-sm"
                color="#fff"
              >
                <v-img
                  max-width="20"
                  max-height="20"
                  src="@/assets/img/common/google.svg"
                  class="me-3"
                ></v-img>
                Github
              </v-btn>
            </div>
          </div> -->
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted">Sign in with credentials</small>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              ref="email"
               v-model="email"
              placeholder="Email"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mt-6 mb-4"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-email-83</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              hide-details
              outlined
               v-model="password"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              type="password"
              ref="password"
              placeholder="Password"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mb-6"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem"
                  >ni-lock-circle-open</v-icon
                >
              </template>
            </v-text-field>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              v-on:click="checkForm"
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6"
              color="#5e72e4"
              >Sign In</v-btn
            >
          </v-card-text>
        </v-card>
        <v-card class="card-shadow bg-secondary" v-else>
          <div style="" >
                <v-btn
                    fab
                    dark
                    class="ml-5 mt-5"
                    @click="pageFlag='login'"
                    small
              color="#172B4D"
            >
              <v-icon>fa fa-arrow-left</v-icon>
            </v-btn>
            </div>
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted" >Forgot password</small>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              ref="email"
               v-model="email"
              placeholder="Email"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon mt-6 mb-4"
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">ni-email-83</v-icon>
              </template>
            </v-text-field>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              v-on:click="forgotPass"
              :disabled="buttonFlag" 
              class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm my-6"
              color="#5e72e4"
              >Continue</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>

      <div class="d-flex justify-space-between mt-4">
        <!-- <a
          href="javascript:;"
          class="text-light text-decoration-none btn-hover no-default-hover"
          ><small>Forgot password?</small></a
        > -->
        <p></p>
        <a
        v-if="pageFlag == 'login'"
          href="javascript:;"
          class="text-light text-decoration-none btn-hover no-default-hover"
          ><small  @click="pageFlag='forgot'">Forgot password?</small></a
        >
      </div>
    </v-col>
  </v-row>
</template>
<script>
import Vue from 'vue'
// import axios from "axios";
export default {
  name: "login",
  data() {
    return {
      checkbox: false,
      email : '',
      pageFlag: 'login',
      password : '',
      showPassword : false,
      dialog: false,
      buttonFlag: false
    };
  },
  beforeCreate (){
    if(localStorage.getItem('token'))
      this.$router.push('/dashboard')
  },
  methods: {
gotohome: function () {
    let self = this;
    self.$router.push({ name: "Home" });
},

gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},
forgotPass(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }else{
    this.buttonFlag =true;
    axios({
          method: "POST",
          withCredentials: false,
          url: "/auth/forgot-password-admin",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
      }
},
/**
 * checkForm
 * login the user with valid email/pass
 */
checkForm: function(e) {
      var loginData = {};
      var header = {};
      let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        var email = this.email;
        axios({
          method: "POST",
          url: "auth/login-admin",
          data: {
            email: this.email,
            password: this.password,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.flag == 1) {
              let result = data.data;
              localStorage.setItem("token", data.token.token);
              localStorage.setItem("user_id", result.user_id);
              localStorage.setItem("first_name", result.first_name);
              localStorage.setItem("email", result.email);
              localStorage.setItem("is_verified", result.is_verified);
              localStorage.setItem("is_admin", result.is_admin);
              localStorage.setItem("name", result.first_name+' '+result.last_name);
              this.$router.push("/dashboard");
              location.reload();
            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }
    },

},
};
</script>
<style>
.btn-primary{
  height: 43px;
  background-color: #F06B66!important;
  border-color: #F06B66!important;
}
</style>